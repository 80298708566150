import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import WhiteArrowDownIcon from '../../Home/assets/ArrowDownIcon.svg';
import WhiteDownloadIcon from '../../Home/assets/WhiteDownloadIcon.svg';
import OrangeDownloadIcon from './assets/OrangeDownloadIcon.svg';
import OrangeArrowDownIcon from './assets/OrangeArrowDownIcon.svg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

interface NavHeaderProps {
  Dropdown?: boolean;
  Active: boolean;
  Download?: boolean;
  title: string;
}

function NavHeader({ Dropdown, Active, Download, title }: NavHeaderProps) {
  const [isDropdownVisible, setDropdownVisibility] = useState(false);
  const dropdownTimerRef = useRef<NodeJS.Timeout | undefined>();
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );
  const { t } = useTranslation();

  const handleDropdownEnter = () => {
    clearTimeout(dropdownTimerRef.current);
    setDropdownVisibility(true);
  };

  const handleDropdownLeave = () => {
    dropdownTimerRef.current = setTimeout(() => {
      setDropdownVisibility(false);
    }, 100);
  };

  const toValues: { [key: string]: string } = {
    'About Us': '/about',
    Home : '',
    'Contact Us': '/contact',
    Applications: '/applications',
    Downloads: '/downloads',
    Products: '/products',
    Videos: '/videos',
    Systems: '/systems',
    blogs: '/blogs',
  };

  const toValue = toValues[title];

  // Prepend the current language to the path
  const toValueWithLang = `/${currentLanguage}${toValue}`;

  return (
    <Link
      to={toValueWithLang} // Use the language-prefixed URL
      className="relative group flex flex-row items-center justify-center gap-1 hover:cursor-pointer"
      onMouseEnter={handleDropdownEnter}
      onMouseLeave={handleDropdownLeave}
    >
      {Download && (
        <img
          src={Active ? OrangeDownloadIcon : WhiteDownloadIcon}
          alt="Download"
          className={`${
            Active
              ? 'w-[0.75rem] h-[1rem]'
              : 'w-[0.4375rem] h-[0.5rem] group-hover:headerIconsHover'
          }`}
        />
      )}

      <div
        className={`${
          Active
            ? 'font-bold text-base text-[#FBAD4A]'
            : 'font-normal text-xs text-white group-hover:text-[#85C556] group-hover:cursor-pointer'
        } leading-[14.06px`}
      >
        {t(title)}
      </div>

      {Dropdown && isDropdownVisible && (
        <div className="absolute mt-[10rem] ml-[4rem] flex items-center justify-left p-[1rem] w-[8.875rem] min-h-[7rem] bg-[#FBAD4A] shadow-md">
          <ul className="text-[#1C4585]">
            {[1, 2, 3, 4].map((item) => (
              <li
                key={item}
                className="text-xs leading-6 font-normal hover:text-white hover:cursor-pointer"
              >
                Test Item {item}
              </li>
            ))}
          </ul>
        </div>
      )}

      {Dropdown && (
        <img
          src={Active ? OrangeArrowDownIcon : WhiteArrowDownIcon}
          alt="Dropdown"
          className={`${
            currentLanguage === 'ar'
              ? Active
                ? 'self-end'
                : 'self-end mb-[0.1rem]'
              : ''
          } ${
            Active
              ? 'w-[0.75rem] h-[0.4375rem]'
              : 'group-hover:headerIconsHover cursor-pointer w-[0.375rem] h-[0.21875rem]'
          } `}
        />
      )}
    </Link>
  );
}

export default NavHeader;
